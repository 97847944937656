@font-face {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    src: url('/fonts/Inter-Light.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url('/fonts/Inter-Regular.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url('/fonts/Inter-Medium.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url('/fonts/Inter-SemiBold.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url('/fonts/Inter-Bold.woff2');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: url('/fonts/Inter-ExtraBold.woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/amatic-sc-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/amatic-sc-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/amatic-sc-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/amatic-sc-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/roboto-slab-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/roboto-slab-500.woff2') format('woff2');

}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/roboto-slab-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('/fonts/roboto-slab-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/playfair-display-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/playfair-display-500.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/playfair-display-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/playfair-display-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/kalam-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/kalam-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/kalam-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Kalam';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/kalam-700.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/oswald-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/oswald-500.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/oswald-600.woff2') format('woff2');
}

@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/oswald-700.woff2') format('woff2');
}

body {
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}
